import React, { Component } from 'react';

import Container from '../../components/Container';
import TopContainer from '../../components/TopContainer';

import WatchdogVertLuzes from '../../components/Images/watchdog_vert_luzes.jpg';
import { ReactComponent as LogoWD } from '../../components/Images/logo_wd_hor_bk.svg';
import { ReactComponent as Red } from '../../components/MotorAnimation/red.svg';
import { ReactComponent as Blue } from '../../components/MotorAnimation/blue.svg';
import { ReactComponent as Yellow } from '../../components/MotorAnimation/yellow.svg';
import { ReactComponent as White } from '../../components/MotorAnimation/white.svg';
import '../../components/MotorAnimation/index.scss';

export default class Product extends Component {
  render() {
    return (
      <main id='main' className='main'>
        <TopContainer>
          <article id='cont-prod'>
            <div id='logo_image'>
              <LogoWD id='logowd' alt='Logo Watchdog' />

              <div id='logo_msg'>
                <p>
                  Nosso equipamento atua como um cão de guarda para seu motor.
                </p>
                <p>
                  A qualquer perigo ele desliga o motor e sinaliza o defeito.
                </p>
                <p>O Watchdog Dussou é produzido em versões 12v e 24v.</p>
                <p id='obs'>
                  Produzimos também versão específica para Grupos geradores com
                  controle de sobrevelocidade.
                </p>
              </div>
            </div>
            <div id='wd_img'>
              <img
                id='wd-main-vert'
                src={WatchdogVertLuzes}
                alt='Protetor de Motor Watchdog'
              />
            </div>
          </article>
        </TopContainer>
        <Container id='function'>
          <article id='cont'>
            <h1>FUNCIONAMENTO DO WATCHDOG</h1>
            <p id='monit'>Watchdog monitorando funcionamento do motor:</p>
            <Red />
            <Blue style={{ display: 'none' }} />
            <Yellow style={{ display: 'none' }} />
            <White style={{ display: 'none' }} />
          </article>
        </Container>
      </main>
    );
  }
}
