import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import LinhaHor from '../LinhaHor';
import './index.scss';

import Logo from '../Images/logo.svg';

class Menu extends Component {
  render() {
    return (
      <>
        <nav id='menu'>
          <div id='cont'>
            <NavLink to='/' title='Home'>
              <img src={Logo} alt='Logo Dussou' />
            </NavLink>
            <ul>
              <li key='quem_somos' className='menu_option'>
                <NavLink activeClassName='active_option' to='/about'>
                  QUEM SOMOS
                </NavLink>
              </li>
              <li key='produtos' className='menu_option'>
                <NavLink activeClassName='active_option' to='/product'>
                  PRODUTOS
                </NavLink>
              </li>
              <li key='contato' className='menu_option'>
                <NavLink activeClassName='active_option' to='/contact'>
                  CONTATO
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
        <LinhaHor />
      </>
    );
  }
}

export default Menu;
