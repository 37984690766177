import React, { Component } from 'react';
import LinhaVert from '../LinhaVert';

import Montesa from '../Images/montesa.jpg';

import './index.scss';

export default class Parners extends Component {
  constructor(props) {
    super(props);
    this.state = '';
  }

  render() {
    return (
      <article className='partners'>
        <figure>
          <img src={Montesa} alt='Montesa' />
          <figcaption>
            <h3>MONTESA AGROPECUÁRIA</h3>
            <blockquote>
              <p>
                &quot;Somos clientes Dussou há mais de 10 anos. Como a Montesa
                sempre investiu em qualidade e inovação no setor de
                agropecuária, optamos pelo Protetor de Motor Watchdog Dussou.
                Nossa empresa possui equipamentos instalados em diversos grupos
                geradores de pivôs de irrigação e em motobombas de
                irrigação.&quot;
              </p>
            </blockquote>
            <h4>Sílvio, Supervisor de Manut. Mecânica. (34) 9823-7421</h4>
          </figcaption>
        </figure>
        <LinhaVert />
        <figure>
          <img src='https://picsum.photos/200/150' alt='Agrop. Edson Kayano' />
          <figcaption>
            <h3>AGROPECUÁRIA EDSON KAYANO</h3>
            <blockquote>
              <p>
                &quot;A Agropecuaria Edson Kayano atua no setor de produção de
                agrícola no Paraná. Os Protetores de Motor Watchdog estão
                instalados em nossas motobombas.&quot;
              </p>
            </blockquote>
            <h4>Takahito Mori, Engenheiro Agrônomo. (42) 9973-4036</h4>
          </figcaption>
        </figure>
      </article>
    );
  }
}
