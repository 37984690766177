import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import DarkContainer from '../../components/DarkContainer';
import TopContainer from '../../components/TopContainer';
import Qualities from '../../components/Qualities';
import CardList from '../../components/CardList';
import SeeMoreButton from '../../components/SeeMoreButton';
import WatchdogHorLuzes from '../../components/Images/watchdog_hor_luzes_cut.png';
import { ReactComponent as LogoWD } from '../../components/Images/logo_wd_hor_bk.svg';

export default class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qualitiesList: [{ qual: '', desc: '' }],
    };
  }

  componentDidMount() {
    this.setState({
      qualitiesList: [
        {
          qual: 'Confiabilidade',
          desc: 'Desliga o motor ao detectar um problema',
        },
        {
          qual: 'Economia',
          desc: 'Evita altos custos de retífica de motor',
        },
        {
          qual: 'Durabilidade',
          desc: 'Construção resistente a vibrações e intempéries',
        },
      ],
    });
  }

  render() {
    const { qualitiesList } = this.state;
    return (
      <main id='main' className='main'>
        <TopContainer>
          <article id='cont'>
            <div id='logo_msg'>
              <LogoWD id='logowd' alt='Logo Watchdog' />
              <h2>O melhor amigo do seu motor</h2>
            </div>
            <img
              id='wd-main'
              src={WatchdogHorLuzes}
              alt='Protetor de Motor Watchdog'
            />
            <SeeMoreButton id='seemore' name='seemore'>
              <NavLink activeClassName='active_seemore' to='/product'>
                Saiba Mais!
              </NavLink>
            </SeeMoreButton>
          </article>
        </TopContainer>
        <DarkContainer>
          <article id='cont'>
            <CardList>
              {qualitiesList.map((quality) => (
                <Qualities
                  key={quality.qual}
                  qual={quality.qual}
                  desc={quality.desc}
                />
              ))}
            </CardList>
          </article>
        </DarkContainer>
        {/* <Container>
          <article id='cont'>
            <h1>Site em construção.</h1>
          </article>
        </Container> */}
      </main>
    );
  }
}
