import React, { useEffect } from 'react'; // sempre necessário quando usamos JSX
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Main from './pages/Main';
import Contact from './pages/Contact';
import About from './pages/About';
import Product from './pages/Product';
import FourOFourPage from './pages/FourOFourPage';

import Menu from './components/Menu';
import Foot from './components/Foot';

export default function AppRoutes() {
  useEffect(() => {}, []);

  return (
    <BrowserRouter>
      <Menu />
      <Routes>
        <Route path='/' exact element={<Main />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/about' element={<About />} />
        <Route path='/product' element={<Product />} />
        <Route
          path='/facebook'
          element={() => {
            window.location.href = 'https://fb.me/dussouprotecoeseletronicas';
            return null;
          }}
        />
        <Route
          path='/whatsapp'
          element={() => {
            window.location.href =
              'https://wa.me/5511963585426?text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Vigia%20de%20Motor.';
            return null;
          }}
        />
        <Route
          path='/developer'
          element={() => {
            window.location.href = 'https://www.theoldpinkeye.com.br';
            return null;
          }}
        />
        <Route element={<FourOFourPage />} />
      </Routes>
      {/*         </div>
      </{ OverlayScrollbars } Component> */}
      <Foot />
    </BrowserRouter>
  );
}
