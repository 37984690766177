import styled from 'styled-components';

const Container = styled.section`
  background: #fff;
  display: block;
  overflow-y: hidden;

  padding: 25px;

  height: 100%;
  width: 100%;

  &#about {
    padding-bottom: 10px;
  }

  &#clients {
    padding-top: 15px;
  }

  #function {
    height: 100%;
    margin-bottom: 40px;
    #monit {
      opacity: 1;
    }
  }

  #cont {
    display: flex;
    flex-direction: column;
    max-width: 60%;
    height: 100%;
    margin-top: 15px;
    margin-bottom: 15px;

    margin-left: auto;
    margin-right: auto;

    h2 {
      font-family: 'Yanone Kaffeesatz', 'Noto Mono', 'Arial Narrow', sans-serif;
      font-weight: 400;
      font-size: 24px;
      margin-bottom: 15px;
    }

    h1 {
      font-family: 'Yanone Kaffeesatz', 'Noto Mono', 'Arial Narrow', sans-serif;
      font-weight: 400;
      font-size: 28px;
      margin-bottom: 15px;
      text-align: left;
    }

    p {
      text-align: left;
      line-height: 1.4;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 700px) {
    #cont {
      text-align: center;
      max-width: 90%;
      width: 90%;
    }
  }
`;

export default Container;
