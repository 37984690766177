import styled from 'styled-components';

const CardList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*   border: 1px solid #030; */
  /* TODO: Fix alignment of flex items in lower res mode */
  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;
export default CardList;
