import React, { Component } from 'react';

import Container from '../../components/Container';

import ContactForm from '../../components/ContactForm';

export default class Contact extends Component {
  render() {
    return (
      <main id='main' className='main'>
        <Container>
          <article id='cont'>
            <ContactForm />
          </article>
        </Container>
      </main>
    );
  }
}
