import React, { Component } from 'react';

import Container from '../../components/Container';

import Partners from '../../components/Partners';

export default class About extends Component {
  render() {
    return (
      <main id='main' className='main'>
        <Container id='about'>
          <article id='cont'>
            <h1>SOBRE A DUSSOU PROTEÇÕES ELETRÔNICAS</h1>
            <p>
              Nosso CEO, o projetista elétrico e eletrônico Maurilo Duarte de
              Souza, possui mais de 40 anos de experiência em manutenção
              elétrica e eletrônica.
            </p>
            <p>
              Ele idealizou o &quot;Protetor de Motor Watchdog&quot;,
              carro-chefe da Dussou, devido às dificuldades parada por falhas em
              máquinas a diesel instaladas em locais de difícil acesso.
            </p>
            <p>
              O &quot;Protetor de Motor Watchdog&quot; ajuda na identificação
              dos defeitos em motores de forma segura, forçando a parada em caso
              de falhas, cooperando assim para a redução de custos e facilidade
              na manutenção mecânica e elétrica quando instalado.
            </p>
            <p>
              Nosso &quot;Protetor de Motor Watchdog&quot; foi desenvolvido para
              ser utilizado em grupos geradores, motobombas, compressores e
              demais máquinas que usam motores diesel, sinalizando de forma
              simples possíveis falhas nos equipamentos, fazendo a parada
              imediata do motor de forma segura. Tudo isso sem necessidade de
              acompanhamento in loco.
            </p>
            <p>
              Nosso equipamento está em operação em grupos geradores e
              motobombas de irrigação em plantações em Minas Gerais e no estado
              do Paraná, há mais de 10 anos proporcionando redução palpável de
              gastos de manutenção.
            </p>
          </article>
        </Container>
        <Container id='clients'>
          <article id='cont'>
            <h2>NOSSOS CLIENTES</h2>

            <Partners />
          </article>
        </Container>
      </main>
    );
  }
}
