import React, { Component } from 'react';

import axios from 'axios';

export default class RecaptchaComponent extends Component {
  constructor(props) {
    super(props);

    this.handleLoaded = this.handleLoaded.bind(this);

    this.state = { recaptchaValid: 0 };
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_reCAPTCHA_site_key}`;
    script.addEventListener('load', this.handleLoaded);
    document.body.appendChild(script);
  }

  handleLoaded = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_reCAPTCHA_site_key, {
          action: 'homepage',
        })
        .then(async (token) => {
          await axios
            .post(process.env.REACT_APP_CAPTCHA_URI, {
              token,
            })
            .then((/* res */) => {
              this.setState({ recaptchaValid: 1 });

              /* console.log(
                'Verification valid:',
                res.data.google_response.success
              ); */
              return 1;
            })
            .catch((/* res */) => {
              /* console.log(
                'Verification invalid:',
                res.data.google_response.success
              ); */
              this.setState({ recaptchaValid: 0 });
              return 0;
            });
        });
    });
  };

  render() {
    const { recaptchaValid } = this.state;

    return (
      <div
        className='g-recaptcha'
        data-sitekey={process.env.REACT_APP_reCAPTCHA_site_key}
        data-size='invisible'
        data-recaptchavalid={recaptchaValid}
        id='recaptcha'
      />
    );
  }
}
