import styled from 'styled-components';

const TopContainer = styled.header`
  display: block;
  background: #fff;
  padding: 25px 25px 25px 25px;
  height: 100%;
  width: 100%;

  #cont {
    position: relative;
    display: flex;
    flex-direction: row;

    max-width: 60%;
    height: 100%;
    margin-top: 15px;
    margin-bottom: 15px;

    margin-left: auto;
    margin-right: auto;
    #logo_msg {
      max-width: 40%;

      z-index: 1;
      position: absolute;
      #logowd {
        margin-top: -2%;
        width: 200%;
      }
      h2 {
        width: 100%;
        margin-top: 20px;
        font-size: 35px;
      }
    }
    #wd-main {
      width: 70%;
      height: auto;
      margin-left: auto;
      position: relative;
    }
    #seemore {
      z-index: 1;
      top: 95%;
      left: 86%;
      transform: scale(2);
      margin-left: auto;
      position: absolute;
      box-shadow: 2px 2px 2px #ddd;
    }
  }

  #cont-prod {
    display: flex;
    flex-direction: row;
    overflow: none;

    max-width: 58vw;
    //height: 100%;
    margin-top: 15px;
    margin-bottom: 15px;

    margin-left: auto;
    margin-right: auto;
    #logo_image {
      display: flex;
      flex-direction: column;
      width: 45%;
      #logowd {
        width: 76vw;
        max-width: 200%;
        //height: 10%;
        //position: absolute;
        //top: 100px;
        z-index: 1;
      }
      #logo_msg {
        display: flex;
        flex-direction: column;
        margin-top: 20%;
        //width: 60%;
        //top: 17%;

        //position: absolute;

        #obs {
          margin-top: 20px;
        }
      }
    }
    div#wd_img {
      width: 45vw;
      text-align: right;
      img {
        max-width: 100%;
        min-height: 350px;
        max-height: 500px;
        //margin-left: auto;
        //margin-right: 0;

        z-index: 0;
      }
    }
  }

  h2 {
    font-family: 'Yanone Kaffeesatz', 'Noto Mono', 'Arial Narrow', sans-serif;
    font-weight: 400;
    font-size: 28px;
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
  }

  p {
    text-align: left;
    line-height: 1.2;
  }

  @media (max-width: 1000px) {
    padding-bottom: 25px;
    #linha,
    #cont {
      max-width: 65%;
      width: 65%;
      flex-direction: column;

      #logo_msg {
        width: 40%;
        #logowd {
          width: 150%;
        }
        h2 {
          width: 160px;
          font-size: 28px;
        }
      }
      #seemore {
        transform: scale(1.75);
        top: 100%;
        left: 75%;
        box-shadow: 2px 2px 2px #ddd;
      }
    }

    #cont-prod {
      max-width: 90%;
      width: 90%;

      //#logo_image {
      //  #logo_msg {
      //    width: 70%;
      //  }
      //}
      //#wd-main-vert {
      //  height: 50%;
      //  margin-left: auto;
      //  position: relative;
      //}
    }
  }

  @media (max-width: 900px) {
    padding-bottom: 25px;
    #linha,
    #cont {
      max-width: 70%;
      width: 70%;
      flex-direction: column;

      #logo_msg {
        width: 45%;
        #logowd {
          width: 160%;
        }
        h2 {
          width: 150px;
          font-size: 28px;
        }
      }
      #seemore {
        transform: scale(1.5);
        top: 100%;
        left: 75%;
        box-shadow: 2px 2px 2px #ddd;
      }
    }

    #cont-prod {
      max-width: 90%;
      width: 90%;

      //#logo_image {
      //  #logo_msg {
      //    width: 70%;
      //  }
      //}
      //#wd-main-vert {
      //  height: 50%;
      //  margin-left: auto;
      //  position: relative;
      //}
    }
  }

  @media (max-width: 700px) {
    padding-bottom: 25px;
    #linha,
    #cont {
      max-width: 90%;
      width: 90%;
      flex-direction: column;

      #logo_msg {
        width: 60%;
        #logowd {
          width: 180%;
        }
        h2 {
          width: 150px;
          font-size: 26px;
        }
      }
      #seemore {
        transform: scale(1);
        top: 100%;
        left: 75%;
        box-shadow: 2px 2px 2px #ddd;
      }
    }

    #cont-prod {
      max-width: 90%;
      width: 90%;

      //#logo_image {
      //  #logo_msg {
      //    width: 70%;
      //  }
      //}
      //#wd-main-vert {
      //  height: 50%;
      //  margin-left: auto;
      //  position: relative;
      //}
    }
  }

  @media (max-width: 400px) {
    padding-bottom: 25px;
    #linha,
    #cont {
      max-width: 90%;
      width: 90%;
      flex-direction: column;

      #logo_msg {
        width: 100%;
        #logowd {
          width: 110%;
        }
      }
    }

    #cont-prod {
      max-width: 90%;
      width: 90%;
      flex-direction: column;
      //#logo_image {
      //  #logo_msg {
      //    width: 500%;

      //    #obs {
      //      width: 55%;
      //    }
      // }
      //}
      //#wd-main-vert {
      //  height: 50%;
      //  margin-left: auto;
      //  position: relative;
      //}
    }
  }
`;

export default TopContainer;
