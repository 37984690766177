import React, { Component } from 'react';
import './index.scss';
import { ReactComponent as RoundAbstract } from '../Images/round-abstract.svg';

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = { qual: '', desc: '' };
  }

  componentDidMount() {
    const { qual, desc } = this.props;
    this.setState({ qual, desc });
  }

  render() {
    const { qual, desc } = this.state;
    return (
      <article id='quality'>
        <div id='word'>
          <RoundAbstract id='abstract' />
          <h3 id='text'>{qual}</h3>
        </div>
        <p id='desc'>{desc}</p>
      </article>
    );
  }
}
