import styled from 'styled-components';

// criando a animação para uso no ícone de loading do botão

const SeeMoreButton = styled.button`
  font-family: 'Yanone Kaffeesatz', 'Noto Mono', 'Arial Narrow', sans-serif;
  background: #f07c00;
  width: 100px;
  height: 40px;
  border: 0;
  padding: 10px 10px 4px 10px;
  border-radius: 4px;
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  margin-top: -40px;
  margin-bottom: 2px;
  border-bottom: 3px solid #f07c00;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
    background: #ccc;
    color: #000;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  &:hover {
    background: #333;
    color: #fff;
  }
`;

export default SeeMoreButton;
