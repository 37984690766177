import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import { FaFacebookSquare, FaWhatsappSquare } from 'react-icons/fa';

import './index.scss';
import LogoTope from '../Images/logo-tope-mini.svg';

class Foot extends Component {
  render() {
    return (
      <footer>
        <section id='cont'>
          <div id='left'>
            <Link
              to='/facebook'
              target='_blank'
              rel='noopener noreferrer'
              title='Clique aqui para entrar em contato pelo Facebook.'
            >
              <FaFacebookSquare className='fb' />
            </Link>
            <Link
              to='/whatsapp'
              target='_blank'
              rel='noopener noreferrer'
              title='Clique aqui para enviar uma mensagem para nós.'
            >
              <FaWhatsappSquare className='wpp' />
            </Link>
          </div>
          <div id='center'>
            <p id='copy'>
              ©{new Date().getFullYear()} - Dussou Proteções Eletrônicas
            </p>
            <p id='mail'>dussouprotecoes@gmail.com</p>
          </div>
          <div id='right'>
            <p id='dev'>
              Site por:
              <br />
              TheOldPinkEye
            </p>

            <div>
              <Link
                target='_blank'
                rel='noopener noreferrer'
                to='/developer'
                title='The Old Pink Eye'
              >
                <img src={LogoTope} alt='Logo The Old Pink Eye' />
              </Link>
            </div>
          </div>
        </section>
      </footer>
    );
  }
}
export default Foot;
