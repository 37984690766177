import React, { Component } from 'react';

import Container from '../../components/Container';

export default class FourOFourpage extends Component {
  render() {
    return (
      <main id='main' className='main'>
        <Container>
          <article id='cont'>
            <h1>404 - A página solicitada não existe!</h1>
          </article>
        </Container>
      </main>
    );
  }
}
