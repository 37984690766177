/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';

import Favicon from './components/Images/favicon.svg';
import AppRoutes from './routes';
import './App.scss';

import 'overlayscrollbars/overlayscrollbars.css';

function App() {
  useEffect(() => {}, []);

  return (
    <>
      <Helmet>
        <title>Dussou Proteções Eletrônicas</title>
        <link rel='icon' sizes='192x192' href={Favicon} />
        <link rel='shortcut icon' id='favicon' href={Favicon} />
        <meta charset='utf-8' />
        <meta name='theme-color' content='#f07c00' />
        {/* <!-- Windows Phone --> */}
        <meta name='msapplication-navbutton-color' content='#f07c00' />
        {/* <!-- iOS Safari --> */}
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='apple-mobile-web-app-status-bar-style' content='black' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Helmet>
      <AppRoutes />
    </>
  );
}

export default App;
